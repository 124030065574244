import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';
import Layout from '../views/Layout.vue';
import Store from '@/store/index';
import API, { BASE_URL, setupAxiosToken } from '@/plugins/axios';
import Axios from 'axios';
import store from '@/store/index';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/admin',
    name: 'Layout',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/Layout.vue'),
    meta: {
      title: 'CulturalFit - Admin',
    },
    beforeEnter: (to, from, next) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((Store.getters as any).userData?.user_type === 'admin') {
        next();
      } else {
        next('/');
      }
    },
    children: [
      {
        path: '/',
        name: 'Admin',
        component: () => import(/* webpackChunkName: "about" */ '../views/Admin/Home.vue'),
        meta: {
          title: 'CulturalFit - Admin',
        },
      },
      {
        path: '/admin/new_company',
        name: 'AdminNewCompany',
        component: () => import(/* webpackChunkName: "about" */ '../views/Admin/NewCompany.vue'),
        meta: {
          title: 'CulturalFit - Admin - New Company',
        },
        beforeEnter: (to, from, next) => {
          to.meta.prev = '/';
          next();
        },
      },
      {
        path: '/admin/company/:id',
        name: 'AdminCompany',
        component: () => import(/* webpackChunkName: "about" */ '../views/Admin/Company.vue'),
        meta: {
          title: 'CulturalFit - Admin - Company',
        },
        beforeEnter: (to, from, next) => {
          to.meta.prev = '/';
          next();
        },
      },
      {
        path: '/admin/company/:id/edit',
        name: 'AdminEditCompany',
        component: () => import(/* webpackChunkName: "about" */ '../views/Admin/NewCompany.vue'),
        meta: {
          title: 'CulturalFit - Admin - Edit Company',
        },
        beforeEnter: (to, from, next) => {
          to.meta.prev = `/admin/company/${to.params.id}`;
          next();
        },
      },
      {
        path: '/admin/company/:id/new_campaign',
        name: 'AdminNewCampaign',
        component: () => import(/* webpackChunkName: "about" */ '../views/Admin/NewCampaign.vue'),
        meta: {
          title: 'CulturalFit - Admin - New Campaign',
        },
        beforeEnter: (to, from, next) => {
          to.meta.prev = `/admin/company/${to.params.id}`;
          next();
        },
      },
      {
        path: '/admin/campaign/new',
        name: 'AdminNewCampaign',
        component: () => import(/* webpackChunkName: "about" */ '../views/Admin/NewCampaign.vue'),
        meta: {
          title: 'CulturalFit - Admin - New Campaign',
        },
        beforeEnter: (to, from, next) => {
          to.meta.prev = `/`;
          next();
        },
      },
      {
        path: '/admin/campaign/:id',
        name: 'AdminCampaign',
        component: () => import(/* webpackChunkName: "about" */ '../views/Admin/Campaign.vue'),
        meta: {
          title: 'CulturalFit - Admin - Campaign',
        },
        beforeEnter: (to, from, next) => {
          if (from.name === 'AdminNewCampaign') {
            to.meta.prev = `/`;
          } else if (from.params.id) {
            to.meta.prev = `/admin/company/${from.params.id}`;
          } else {
            to.meta.prev = `/`;
          }
          next();
        },
      },
      {
        path: '/admin/campaign/:campaign_id/edit',
        name: 'AdminEditCampaign',
        component: () => import(/* webpackChunkName: "about" */ '../views/Admin/NewCampaign.vue'),
        meta: {
          title: 'CulturalFit - Admin - Edit Campaign',
        },
        beforeEnter: (to, from, next) => {
          to.meta.prev = `/admin/campaign/${to.params.campaign_id}`;
          next();
        },
      },
    ],
  },
  {
    path: '/psych',
    name: 'Layout',
    component: () => import(/* webpackChunkName: "about" */ '../views/Psych/Layout.vue'),
    meta: {
      title: 'CulturalFit - Psiholog',
    },
    beforeEnter: (to, from, next) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((Store.getters as any).userData?.user_type === 'psych') {
        next();
      } else {
        next('/');
      }
    },
    children: [
      {
        path: '/',
        name: 'Psiholog',
        component: () => import(/* webpackChunkName: "about" */ '../views/Psych/Home.vue'),
        meta: {
          title: 'CulturalFit - Psiholog',
        },
      },
      {
        path: '/psych/campaign/:id',
        name: 'PsychCampaign',
        component: () => import(/* webpackChunkName: "about" */ '../views/Psych/Campaign.vue'),
        meta: {
          title: 'CulturalFit - Psiholog - Campaign',
        },
        beforeEnter: (to, from, next) => {
          to.meta.prev = '/';
          next();
        },
      },
      {
        path: '/psych/application/:campaign_id/:id',
        name: 'PsychApplication',
        component: () => import(/* webpackChunkName: "about" */ '../views/Psych/Application.vue'),
        meta: {
          title: 'CulturalFit - Psiholog - Application',
        },
        beforeEnter: (to, from, next) => {
          to.meta.prev = `/psych/campaign/${to.params.campaign_id}`;
          next();
        },
      },
      {
        path: '/psych/report/:id',
        name: 'PsychReport',
        component: () => import(/* webpackChunkName: "about" */ '../views/Psych/Report.vue'),
        meta: {
          title: 'CulturalFit - Psiholog - Report',
        },
        beforeEnter: (to, from, next) => {
          to.meta.prev = `/psych/campaign/${to.params.id}`;
          next();
        },
      },
    ],
  },
  {
    path: '/',
    name: 'Home',
    component: Layout,
    meta: {
      title: 'CulturalFit - Profil',
    },
    beforeEnter: (to, from, next) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((Store.getters as any).userData?.user_type === 'admin' && !to.path.includes('admin')) {
        next('/admin');
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } else if ((Store.getters as any).userData?.user_type === 'psych' && !to.path.includes('psych')) {
        next('/psych');
      } else {
        next();
      }
    },
    children: [
      {
        path: '/',
        name: 'Home',
        meta: {
          title: 'CulturalFit - Profil',
        },
        component: Home,
      },
      {
        path: '/profile_edit',
        name: 'EditProfile',
        meta: {
          title: 'CulturalFit - Izmeni profil',
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/ProfileEdit.vue'),
      },
      {
        path: '/test/:application_id/new',
        name: 'NewTest',
        meta: {
          title: 'CulturalFit - Novi test',
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/TestIntro.vue'),
      },
      {
        path: '/test/:application_id/submit_or_new',
        name: 'ApplicationSubmitOrNew',
        meta: {
          title: 'CulturalFit - Aplikacija',
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/ApplicationSubmitOrNew.vue'),
      },
      {
        path: '/test/application_sent',
        name: 'ApplicationSent',
        meta: {
          title: 'CulturalFit - Aplikacija poslata',
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/ApplicationSent.vue'),
      },
      {
        path: '/test/:application_id/:questionar_id',
        name: 'Test',
        meta: {
          title: 'CulturalFit - Test',
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/Test.vue'),
      },
    ],
  },
  {
    path: '/auth/',
    name: 'Auth',
    component: () => import(/* webpackChunkName: "about" */ '../views/Auth/Layout.vue'),
    children: [
      {
        path: '/auth/register',
        name: 'Register',
        component: () => import(/* webpackChunkName: "about" */ '../views/Auth/Register.vue'),
        meta: {
          title: 'CulturalFit - Registracija',
        },
      },
      {
        path: '/auth/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "about" */ '../views/Auth/Login.vue'),
        meta: {
          title: 'CulturalFit',
        },
      },
      {
        path: '/auth/:uuid',
        name: 'Auth',
        meta: {
          title: 'CulturalFit - Autentikacija',
        },
        beforeEnter: async (to, from, next) => {
          if (to.params.uuid) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if ((store.state as any).User.isLoggedIn) {
              if (
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (Store.getters as any).userData?.user_type === 'admin' ||
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (Store.getters as any).userData?.user_type === 'psych'
              ) {
                next('/');
              } else {
                await API.post(`/candidate/campaigns/${to.params.uuid}/applications/`);
                next('/');
              }
            } else {
              if (/[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}/.test(to.params.uuid)) {
                localStorage.setItem('campaign_uuid', to.params.uuid);
                next({ name: 'Login' });
              }
            }
          }
        },
      },
      {
        path: '/auth/forgot_password',
        name: 'ForgotPassword',
        component: () => import(/* webpackChunkName: "about" */ '../views/Auth/ForgotPassword.vue'),
        meta: {
          title: 'CulturalFit - Zaboravljena šifra',
        },
      },
      {
        path: '/auth/activate/:uuid',
        name: 'VerfiyToken',
        meta: {
          title: 'CulturalFit - Verifikacija',
        },
        beforeEnter: async (to, from, next) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if (!(Store.getters as any).userData.verifed) {
            const { status } = await Axios.get(`${BASE_URL}/auth/activate/${to.params.uuid}/`);

            if (status === 200) {
              localStorage.setItem('justVerified', 'true');
            }
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if (!(Store.getters as any).isLoggedIn) {
            next({ name: 'Login' });
          } else {
            next('/');
          }
        },
      },
      {
        path: '/auth/password_reset/:uuid',
        name: 'PasswordReset',
        meta: {
          title: 'CulturalFit - Resetuj šifru',
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/Auth/PasswordReset.vue'),
        beforeEnter: async (to, from, next) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if (!(Store.getters as any).isLoggedIn) {
            next();
          } else {
            next('/');
          }
        },
      },
    ],
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  setupAxiosToken();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (
    !['Auth', 'Login', 'Register', 'VerfiyToken', 'ForgotPassword', 'PasswordReset'].includes(to.name || '') &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    !(Store.getters as any).isLoggedIn
  )
    next({ name: 'Login' });
  else next();
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode?.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef: { [key: string]: string }) => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag: HTMLElement) => document.head.appendChild(tag));

  next();
});

export default router;
