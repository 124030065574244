<template>
  <svg
    width="206px"
    height="206px"
    viewBox="0 0 206 206"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>icon-thanks</title>
    <defs>
      <polygon id="path-2" points="0 136 135.790716 136 135.790716 0.209284254 0 0.209284254"></polygon>
    </defs>
    <g id="Korisnik-Prvi" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Korisnik_out" transform="translate(-553.000000, -132.000000)">
        <g id="icon-thanks" transform="translate(553.000000, 132.000000)">
          <!-- <circle id="Oval" fill="#EEF7FE" cx="103" cy="103" r="103"></circle> -->
          <g id="Group-22">
            <g transform="translate(35.000000, 35.000000)">
              <polygon
                id="Fill-1"
                fill="#36506C"
                points="65.7181208 31.033557 71.1946309 31.033557 71.1946309 14.6040268 65.7181208 14.6040268"
              ></polygon>
              <g id="Group-21">
                <path
                  d="M67.8965121,5.98139959 C66.3037268,5.98139959 65.0110315,7.27409489 65.0110315,8.86688016 C65.0110315,10.4596654 66.3037268,11.7523607 67.8965121,11.7523607 C69.4892973,11.7523607 70.7819926,10.4596654 70.7819926,8.86688016 C70.7819926,7.27409489 69.4892973,5.98139959 67.8965121,5.98139959 M67.8965121,17.5233219 C63.1239272,17.5233219 59.2400703,13.639465 59.2400703,8.86688016 C59.2400703,4.0942953 63.1239272,0.210438446 67.8965121,0.210438446 C72.6690969,0.210438446 76.5529538,4.0942953 76.5529538,8.86688016 C76.5529538,13.639465 72.6690969,17.5233219 67.8965121,17.5233219"
                  id="Fill-2"
                  fill="#36506C"
                ></path>
                <g id="Clip-5"></g>
                <polygon
                  id="Fill-4"
                  fill="#36506C"
                  points="65.0098773 121.941939 70.7808384 121.941939 70.7808384 105.483157 65.0098773 105.483157"
                ></polygon>
                <path
                  d="M67.8965121,124.458078 C66.3037268,124.458078 65.0110315,125.750773 65.0110315,127.343558 C65.0110315,128.936344 66.3037268,130.229039 67.8965121,130.229039 C69.4892973,130.229039 70.7819926,128.936344 70.7819926,127.343558 C70.7819926,125.750773 69.4892973,124.458078 67.8965121,124.458078 M67.8965121,136 C63.1239272,136 59.2400703,132.116143 59.2400703,127.343558 C59.2400703,122.570973 63.1239272,118.687117 67.8965121,118.687117 C72.6690969,118.687117 76.5529538,122.570973 76.5529538,127.343558 C76.5529538,132.116143 72.6690969,136 67.8965121,136"
                  id="Fill-6"
                  fill="#36506C"
                ></path>
                <polygon
                  id="Fill-7"
                  fill="#36506C"
                  points="39.4248982 43.7154061 27.7906405 32.0811485 31.87071 28.0010789 43.5049677 39.6353366"
                ></polygon>
                <path
                  d="M26.0062593,23.3369881 C25.2675763,23.3369881 24.5288933,23.6139943 23.9633391,24.1795485 C22.8437726,25.3048859 22.8437726,27.1342806 23.96911,28.259618 C25.0886765,29.3849554 26.9238421,29.3849554 28.0491796,28.259618 C28.5916499,27.7113767 28.8917399,26.9900065 28.8917399,26.2224687 C28.8917399,25.4491599 28.5974209,24.7277898 28.0491796,24.1795485 C27.4836254,23.6197652 26.7449423,23.3369881 26.0062593,23.3369881 M26.0062593,34.8731395 C23.7902102,34.8731395 21.5741611,34.0305791 19.8832695,32.3396875 C16.5130282,28.9636753 16.5130282,23.4697202 19.8832695,20.0994789 C23.2650528,16.7234667 28.7590078,16.7234667 32.1292491,20.0994789 C33.7624311,21.7326609 34.662701,23.9083133 34.662701,26.2224687 C34.662701,28.5308532 33.7624311,30.7065055 32.1292491,32.3396875 C30.4441284,34.0305791 28.2223084,34.8731395 26.0062593,34.8731395"
                  id="Fill-8"
                  fill="#36506C"
                ></path>
                <polygon
                  id="Fill-9"
                  fill="#36506C"
                  points="103.920006 108.210514 92.2857481 96.576256 96.3658176 92.4961865 108.000075 104.130444"
                ></polygon>
                <path
                  d="M109.783879,107.108837 C109.010571,107.108837 108.2892,107.408927 107.74673,107.951398 C106.621393,109.076735 106.621393,110.911901 107.740959,112.037238 C108.837442,113.12795 110.730317,113.12795 111.8268,112.037238 C112.946366,110.911901 112.946366,109.076735 111.8268,107.951398 C111.278558,107.408927 110.551417,107.108837 109.783879,107.108837 M109.783879,118.65076 C107.469724,118.65076 105.294072,117.75049 103.66089,116.117308 C100.284877,112.741295 100.284877,107.24734 103.66089,103.871328 C106.921483,100.610735 112.634734,100.604964 115.906869,103.871328 C119.282881,107.24734 119.282881,112.741295 115.906869,116.117308 C114.267916,117.75049 112.092264,118.65076 109.783879,118.65076"
                  id="Fill-10"
                  fill="#36506C"
                ></path>
                <polygon
                  id="Fill-11"
                  fill="#36506C"
                  points="14.0638323 70.9901227 30.5168425 70.9901227 30.5168425 65.2191616 14.0638323 65.2191616"
                ></polygon>
                <path
                  d="M8.65644172,65.2214699 C7.06365644,65.2214699 5.77096115,66.5141652 5.77096115,68.1069505 C5.77096115,69.6997358 7.06365644,70.9924311 8.65644172,70.9924311 C10.249227,70.9924311 11.5419223,69.6997358 11.5419223,68.1069505 C11.5419223,66.5141652 10.249227,65.2214699 8.65644172,65.2214699 M8.65644172,76.7633922 C3.88385685,76.7633922 0,72.8795354 0,68.1069505 C0,63.3343656 3.88385685,59.4505088 8.65644172,59.4505088 C13.4290266,59.4505088 17.3128834,63.3343656 17.3128834,68.1069505 C17.3128834,72.8795354 13.4290266,76.7633922 8.65644172,76.7633922"
                  id="Fill-12"
                  fill="#36506C"
                ></path>
                <polygon
                  id="Fill-13"
                  fill="#36506C"
                  points="105.273873 70.9901227 121.726883 70.9901227 121.726883 65.2191616 105.273873 65.2191616"
                ></polygon>
                <path
                  d="M127.13312,65.2214699 C125.540335,65.2214699 124.247639,66.5141652 124.247639,68.1069505 C124.247639,69.6997358 125.540335,70.9924311 127.13312,70.9924311 C128.725905,70.9924311 130.0186,69.6997358 130.0186,68.1069505 C130.0186,66.5141652 128.725905,65.2214699 127.13312,65.2214699 M127.13312,76.7633922 C122.360535,76.7633922 118.476678,72.8795354 118.476678,68.1069505 C118.476678,63.3343656 122.360535,59.4505088 127.13312,59.4505088 C131.905705,59.4505088 135.789562,63.3343656 135.789562,68.1069505 C135.789562,72.8795354 131.905705,76.7633922 127.13312,76.7633922"
                  id="Fill-14"
                  fill="#36506C"
                ></path>
                <polygon
                  id="Fill-15"
                  fill="#36506C"
                  points="31.8701329 108.210514 27.7900634 104.130444 39.4243211 92.4961865 43.5043906 96.576256"
                ></polygon>
                <path
                  d="M23.9662245,112.034353 C25.091562,113.165461 26.9209566,113.15969 28.052065,112.034353 C29.1716315,110.909015 29.1716315,109.07962 28.052065,107.954283 C26.9267276,106.834717 25.0973329,106.828946 23.9662245,107.954283 C22.8466581,109.07962 22.8466581,110.909015 23.9662245,112.034353 M26.0091448,118.647874 C23.7930957,118.647874 21.5712757,117.805314 19.886155,116.114422 L19.880384,116.114422 C16.5159137,112.73841 16.5159137,107.250226 19.880384,103.874213 C23.2621673,100.498201 28.7561223,100.498201 32.1321346,103.874213 C35.5081468,107.250226 35.5081468,112.73841 32.1321346,116.114422 C30.4412429,117.805314 28.2251939,118.647874 26.0091448,118.647874"
                  id="Fill-16"
                  fill="#36506C"
                ></path>
                <polygon
                  id="Fill-17"
                  fill="#36506C"
                  points="96.3652405 43.7154061 92.285171 39.6353366 103.919429 28.0010789 107.999498 32.0811485"
                ></polygon>
                <path
                  d="M109.783879,23.3341027 C109.010571,23.3341027 108.2892,23.6341926 107.74673,24.176663 C107.198489,24.7249043 106.898399,25.4462744 106.898399,26.2195832 C106.898399,26.9871211 107.198489,27.7142622 107.740959,28.2625035 C108.837442,29.3532151 110.730317,29.3474442 111.8268,28.2625035 C112.36927,27.7142622 112.66936,26.9871211 112.66936,26.2195832 C112.66936,25.4462744 112.36927,24.7249043 111.8268,24.1824339 L111.8268,24.176663 C111.278558,23.6341926 110.551417,23.3341027 109.783879,23.3341027 M109.783879,34.8760249 C107.469724,34.8760249 105.294072,33.975755 103.66089,32.342573 C102.027708,30.70362 101.127438,28.5279677 101.127438,26.2195832 C101.127438,23.9054278 102.027708,21.7297755 103.66089,20.0965935 C106.921483,16.8360004 112.628963,16.8302294 115.906869,20.0965935 C117.540051,21.7297755 118.440321,23.9054278 118.440321,26.2195832 C118.440321,28.5279677 117.540051,30.70362 115.906869,32.342573 C114.267916,33.975755 112.092264,34.8760249 109.783879,34.8760249"
                  id="Fill-18"
                  fill="#36506C"
                ></path>
                <path
                  d="M53.4691092,86.9722225 L47.6981481,86.9722225 L47.6981481,75.603429 C47.6981481,71.7311141 50.3066225,68.3031632 54.0404344,67.2643902 L60.9021072,65.365744 L67.8503444,71.4771918 L74.4523239,65.365744 L81.6717963,67.293245 C85.4517759,68.3031632 88.0948761,71.7368851 88.0948761,75.6495967 L88.0948761,86.9722225 L82.3239149,86.9722225 L82.3239149,75.6495967 C82.3239149,74.3453595 81.4467288,73.2027092 80.1828883,72.8679935 L76.0393382,71.7599689 L67.9426798,79.2564474 L59.4305121,71.7599689 L55.581281,72.8275967 C54.3405243,73.1738544 53.4691092,74.3165047 53.4691092,75.603429 L53.4691092,86.9722225 Z"
                  id="Fill-19"
                  fill="#36506C"
                ></path>
                <path
                  d="M65.8478209,46.2823297 C64.9879476,46.2823297 64.2088679,46.6401292 63.6548556,47.2980188 C63.1008434,47.9559084 62.8700049,48.7811558 63.0142789,49.6294871 L63.9780294,55.4235321 C64.2088679,56.8143337 65.4034569,57.8242519 66.8115714,57.8242519 L68.6005693,57.8242519 C70.0086838,57.8242519 71.2032728,56.8143337 71.4341112,55.4235321 L72.3978618,49.6294871 C72.5421358,48.7811558 72.3112973,47.9559084 71.7572851,47.2980188 C71.2032728,46.6401292 70.424193,46.2823297 69.5643198,46.2823297 L65.8478209,46.2823297 Z M68.6005693,63.5952131 L66.8115714,63.5952131 C62.5699149,63.5952131 58.9861481,60.5596875 58.2878618,56.3757407 L57.3183403,50.5759247 C56.9028311,48.0655566 57.6068883,45.5090209 59.2516123,43.564207 C60.9021072,41.625164 63.302827,40.5113685 65.8478209,40.5113685 L69.5643198,40.5113685 C72.1093137,40.5113685 74.5158045,41.625164 76.1662994,43.5699779 C77.8052524,45.5147918 78.5093096,48.0655566 78.0938004,50.5759247 L77.1242789,56.3757407 C76.4259926,60.5596875 72.8422258,63.5952131 68.6005693,63.5952131 L68.6005693,63.5952131 Z"
                  id="Fill-20"
                  fill="#36506C"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
