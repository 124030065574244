








































import Vue from 'vue';
import { mapGetters } from 'vuex';
export default Vue.extend({
  data: () => ({
    showHeader: true,
  }),
  computed: {
    ...mapGetters(['userData']),
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
    },
    goBack() {
      this.$router.back();
    },
  },
  watch: {
    $route() {
      this.showHeader = this.$route.path !== '/admin';
    },
  },
  mounted() {
    this.showHeader = this.$route.path !== '/admin';
  },
});
