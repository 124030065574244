



































import Vue from 'vue';

export default Vue.extend({
  name: 'App',
  data: () => ({
    snackbar: false,
    success: true,
    title: '',
    text: '',
  }),
  methods: {
    createSnackBar({ success, title, text }: { success: boolean; title: string; text: string }) {
      this.success = success;
      this.title = title;
      this.text = text;
      this.snackbar = true;
    },
  },
  mounted() {
    this.$root.$on('snackbarCreate', this.createSnackBar);
  },
  beforeDestroy() {
    this.$root.$off('snackbarCreate', this.createSnackBar);
  },
});
