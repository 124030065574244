import store from '@/store';
import { getToken } from '@/utils/utils';
import axios from 'axios';

// export const BASE_URL = 'http://localhost:8000';
export const BASE_URL = 'https://app.culturalfit.rs/api';

const API = axios.create({
  baseURL: BASE_URL,
});

export default API;

export const setupAxiosToken = (access?: string) => {
  const token = access || getToken();
  API.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

API.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    // debugger;
    if (error.request.status === 401 && localStorage.getItem('refresh')) {
      try {
        const { data } = await axios.post(`${BASE_URL}/auth/token_refresh/`, {
          refresh: localStorage.getItem('refresh'),
        });
        if (!data) {
          await store.dispatch('logout');
        } else {
          localStorage.setItem('access', data.access);
          setupAxiosToken(data.access);
          // eslint-disable-next-line no-debugger
          // debugger;
          // error.config.

          return axios({
            ...error.config,
            headers: { ...error.config.headers, Authorization: `Bearer ${data.access}` },
          });
        }
      } catch (e) {
        await store.dispatch('logout');
        return Promise.reject(error);
      }
    } else {
      return Promise.reject(error);
    }
    // error
  }
);
