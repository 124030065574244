import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import User, { UserState } from './user';
import Test, { TestState } from './test';

Vue.use(Vuex);

interface FullRootState {
  User: UserState;
  Test: TestState;
}

interface RootState {
  version: string;
}

export default new Vuex.Store({
  state: {
    version: '0.0.1',
  },
  mutations: {},
  actions: {},
  modules: {
    User,
    Test,
  },
  plugins: [
    createPersistedState({
      reducer: (state: RootState) => {
        if (((state as unknown) as FullRootState).User.isLoggedIn) {
          return state;
        } else {
          return {};
        }
      },
    }),
  ],
});
