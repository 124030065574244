import { Module } from 'vuex';

export interface TestState {
  questions: [];
  answers: [];
  questioners: [];
  active_questioner: null | number;
}

const User: Module<TestState, {}> = {
  state: {
    questions: [],
    answers: [],
    active_questioner: null, //TODO: update active
    questioners: [],
  },
  actions: {
    setQuestions: (
      { commit },
      payload: {
        questions: [];
        answers: [];
      }
    ) => {
      commit('setQuestions', payload);
    },
    setQuestioners: ({ commit }, payload: []) => {
      commit('setQuestioners', payload);
    },
    setActive: ({ commit }, payload: number) => {
      commit('setActive', payload);
    },
    updateAnswer: ({ commit, getters }, payload: { question_id: number; answer: number }) => {
      const index = (getters.questions as { id: number }[]).findIndex(q => q.id === payload.question_id);
      commit('updateAnswer', { index, answer: payload.answer });
    },
    clearQuestions: ({ commit }) => {
      commit('clearQuestions');
    },
    logout: ({ commit }) => {
      commit('logout');
    },
  },
  mutations: {
    setQuestions: (
      state,
      payload: {
        questions: [];
        answers: [];
      }
    ) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (state.questions as any) = [
        ...payload.questions.map((q: { answer_choices: string }) => ({
          ...q,
          answer_choices: JSON.parse(q.answer_choices),
        })),
      ];
      state.answers = payload.answers;
    },
    setQuestioners: (state, payload: []) => {
      state.questioners = payload;
    },
    updateAnswer: (state, payload: { index: number; answer: number }) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (state.answers[payload.index] as any) = {
        answer: payload.answer,
      };
      state.answers = [...state.answers];
    },
    setActive: (state, payload: number) => {
      state.active_questioner = payload;
    },
    clearQuestions: state => {
      state.questions = [];
      state.answers = [];
      state.active_questioner = null;
      // state.questioners = [];
    },
    logout: state => {
      state.questions = [];
      state.answers = [];
      state.active_questioner = null;
      state.questioners = [];
    },
  },
  getters: {
    questions: state => state.questions,
    answers: state => state.answers,
    questioners: state => state.questioners,
  },
};

export default User;
