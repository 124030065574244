













































































































import API from '@/plugins/axios';
import { dateSort } from '@/utils/utils';
import dayjs from 'dayjs';
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'Home',
  data: () => ({
    personalData: {},
    applications: [],
    headers: [
      { text: 'Kompanija', value: 'company_name' },
      { text: 'Kampanja', value: 'position' },
      { text: 'Datum', value: 'created_at', sort: dateSort },
      { text: 'Status', value: 'state' },
      { text: 'Akcija', value: 'actions', sortable: false },
    ],
    isTestStarted: false,
    isTestDone: false,
    isTestOlder: false,
  }),
  computed: {
    ...mapGetters(['userData', 'questioners']),
  },
  methods: {
    formatedDate(date: string) {
      return dayjs(date).format('DD/MM/YY');
    },
    async resend() {
      await API.get('/auth/resend_verification/');
    },
    async getQuestioners() {
      try {
        const { data } = await API.get('candidate/questioners/');
        this.$store.dispatch('setQuestioners', data);
        const qIds = this.applications.reduce((prev, next: any) => {
          if (next.campaign_type == 'company_profile') {
            prev.push(next.questioner_id);
          }
          return prev;
        }, [] as number[]);
        const notCompanyQuestionnaires = data.filter((q: { id: number }) => !qIds.includes(q.id));
        this.isTestStarted = notCompanyQuestionnaires.some((q: { is_finished: boolean }) => !q.is_finished);
        this.isTestDone = !this.isTestStarted && notCompanyQuestionnaires.length;
        this.isTestOlder =
          !this.isTestStarted &&
          this.isTestDone &&
          dayjs((notCompanyQuestionnaires[0] as { finished_at: string }).finished_at) <= dayjs().subtract(6, 'month');
      } catch (e) {
        console.error(e);
      }
    },
    async submitQuestioner(application_id: number) {
      const q = this.questioners.find((q: { is_finished: boolean }) => q.is_finished);
      const application = this.applications.find((a: { id: number }) => a.id === application_id);
      if (application && (application as { campaign_type: string }).campaign_type == 'company_profile') {
        await this.$router.push(`/test/${application_id}/new`);
      } else if (!this.isTestOlder) {
        await API.patch(`candidate/applications/${application_id}/submit/`, {
          questioner_id: q.id,
        });
        this.$router.push('/test/application_sent');
      } else {
        this.$router.push(`/test/${application_id}/submit_or_new`);
      }
    },
  },
  async mounted() {
    const { data, status } = await API.get('/candidate/self/');
    if (status === 200) {
      await this.$store.dispatch('setUserData', {
        verified: true,
      });
      const { applications, ...allData } = data;
      this.personalData = { ...allData };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.applications = applications.map((a: any) => ({
        id: a.id,
        created_at: a.created_at,
        is_completed: a.is_completed,
        state: a.campaign.state,
        questioner_id: a.questioner_id,
        position: a.campaign.position,
        company_name: a.campaign.company.name,
        campaign_type: a.campaign.campaign_type,
      }));

      await this.getQuestioners();

      if (localStorage.getItem('justVerified') === 'true') {
        localStorage.removeItem('justVerified');
        this.$root.$emit('snackbarCreate', {
          success: true,
          title: 'Email Verifikovan',
          text: 'Bravo! Sada možete da uradite test.',
        });
      }
    }
  },
});
