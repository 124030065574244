import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors';
import { Ripple } from 'vuetify/lib/directives';
import ThanksIcon from '@/components/Icons/ThanksIcon.vue';
import SuccessIcon from '@/components/Icons/SuccessIcon.vue';
import TestIcon from '@/components/Icons/TestIcon.vue';
import VerifiedIcon from '@/components/Icons/VerifiedIcon.vue';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#36506C',
        secondary: '#b0bec5',
        accent: '#8c9eff',
        error: '#b71c1c',
        black: colors.grey.darken2,
        white: '#F4F7F7',
      },
    },
    options: {
      customProperties: true,
      variations: true,
    },
  },
  icons: {
    values: {
      'cf-thanks': {
        component: ThanksIcon,
      },
      'cf-test': {
        component: TestIcon,
      },
      'cf-success': {
        component: SuccessIcon,
      },
      'cf-verified': {
        component: VerifiedIcon,
      },
    },
  },
  directives: {
    Ripple,
  },
});

export type VForm = Vue & { validate: () => boolean };
