import router from '@/router';
import { Module } from 'vuex';

export interface UserState {
  isLoggedIn: boolean;
  userData:
    | {
        user_id: number;
        user_type: 'candidate';
        verified: boolean;
        email: string;
      }
    | {};
}

const User: Module<UserState, {}> = {
  state: {
    isLoggedIn: false,
    userData: {},
  },
  actions: {
    setUserData: ({ commit }, payload: UserState['userData']) => {
      commit('setUserData', payload);
    },
    logout: ({ commit }) => {
      commit('logout');
    },
  },
  mutations: {
    setUserData: (state, payload: UserState['userData']) => {
      state.userData = { ...state.userData, ...payload };
      state.isLoggedIn = true;
    },
    logout: state => {
      localStorage.removeItem('access');
      localStorage.removeItem('refresh');
      sessionStorage.clear();
      state.isLoggedIn = false;
      state.userData = {};
      router.push('/auth/login');
    },
  },
  getters: {
    isLoggedIn: state => state.isLoggedIn,
    userData: state => state.userData,
  },
};

export default User;
