<template>
  <svg
    width="206px"
    height="206px"
    viewBox="0 0 206 206"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>icon-test</title>
    <defs>
      <polygon id="path-1" points="0.51975 0.30752 29.788 0.30752 29.788 21.27392 0.51975 21.27392"></polygon>
      <polygon
        id="path-3"
        points="0.937114286 0.865942857 37.0232571 0.865942857 37.0232571 37 0.937114286 37"
      ></polygon>
    </defs>
    <g id="Korisnik-Prvi" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Korisnik_onboard" transform="translate(-553.000000, -132.000000)">
        <g id="icon-test" transform="translate(553.000000, 132.000000)">
          <!-- <circle id="Oval" fill="#EEF7FE" cx="103" cy="103" r="103"></circle> -->
          <g id="Group-19" transform="translate(35.000000, 58.000000)">
            <path
              d="M54.5714286,57 L49,57 L49,45.9503205 C49,42.1866987 51.5182857,38.8549679 55.123,37.8453526 L61.753,36 L68.4554286,41.9399038 L74.8291429,36 L81.799,37.8733974 C85.4482857,38.8549679 88,42.1923077 88,45.9951923 L88,57 L82.4285714,57 L82.4285714,45.9951923 C82.4285714,44.7275641 81.5817143,43.6169872 80.3615714,43.2916667 L76.3668571,42.2147436 L68.5501429,49.5008013 L60.3322857,42.2147436 L56.6105714,43.2524038 C55.4127143,43.5889423 54.5714286,44.6995192 54.5714286,45.9503205 L54.5714286,57 Z"
              id="Fill-1"
              fill="#36506C"
            ></path>
            <path
              d="M66.2292985,18.5 C65.4106055,18.5 64.6688367,18.841 64.1413567,19.468 C63.6138766,20.095 63.3995879,20.8815 63.5314579,21.6845 L64.4490533,27.212 C64.6743313,28.5375 65.8062155,29.5 67.1468939,29.5 L68.8502149,29.5 C70.1908933,29.5 71.3282721,28.5375 71.5480555,27.212 L72.4711456,21.6845 C72.6030156,20.8815 72.3832322,20.095 71.8502576,19.468 C71.3282721,18.841 70.5865033,18.5 69.773305,18.5 L66.2292985,18.5 Z M68.8502149,35 L67.1468939,35 C63.1138695,35 59.701733,32.107 59.0313938,28.1195 L58.1137983,22.592 C57.7126937,20.1995 58.3830329,17.763 59.9489893,15.915 C61.5149456,14.0615 63.806187,13 66.2292985,13 L69.773305,13 C72.1909218,13 74.4766686,14.0615 76.042625,15.9095 C77.6140759,17.7575 78.2844151,20.194 77.8888051,22.5865 L76.965715,28.1195 C76.3008704,32.107 72.8887339,35 68.8502149,35 L68.8502149,35 Z"
              id="Fill-3"
              fill="#36506C"
            ></path>
            <g id="Group-7" transform="translate(106.000000, 39.000000)">
              <mask id="mask-2" fill="white">
                <use xlink:href="#path-1"></use>
              </mask>
              <g id="Clip-6"></g>
              <path
                d="M29.79075,21.27392 L24.29075,21.27392 L24.29075,10.28672 C24.29075,9.02112 23.45475,7.91232 22.25575,7.58752 L18.30125,6.51232 L10.59025,13.78672 L0.51975,4.75392 L4.15525,0.55392 L10.49125,6.23792 L16.78875,0.30752 L23.67475,2.17792 C27.27725,3.15232 29.79075,6.48992 29.79075,10.28672 L29.79075,21.27392 Z"
                id="Fill-5"
                fill="#36506C"
                mask="url(#mask-2)"
              ></path>
            </g>
            <path
              d="M114.233359,21.5 C113.414604,21.5 112.67278,21.841 112.14526,22.468 C111.612245,23.095 111.39794,23.8815 111.535315,24.6845 L112.45298,30.212 C112.67278,31.5375 113.810244,32.5 115.151023,32.5 L116.854472,32.5 C118.195251,32.5 119.332715,31.5375 119.552515,30.212 L120.47018,24.69 C120.60206,23.8815 120.387755,23.095 119.85474,22.468 C119.332715,21.841 118.590891,21.5 117.777631,21.5 L114.233359,21.5 Z M116.854472,38 L115.151023,38 C111.112201,38 107.699808,35.107 107.029418,31.1195 L106.111754,25.592 C105.716114,23.1995 106.381009,20.7685 107.947083,18.915 C109.518652,17.0615 111.810065,16 114.233359,16 L117.777631,16 C120.200925,16 122.486843,17.0615 124.052917,18.915 C125.618991,20.763 126.283886,23.1995 125.888246,25.592 L124.970582,31.1195 C124.305687,35.107 120.893294,38 116.854472,38 L116.854472,38 Z"
              id="Fill-8"
              fill="#36506C"
            ></path>
            <path
              d="M5.49554671,61 L0,61 L0,49.9951923 C0,46.1866987 2.51696039,42.849359 6.11654349,41.8677885 L12.9859769,40 L19.2783779,45.9399038 L25.3729392,40.4655449 L29,44.6778846 L19.1904491,53.4951923 L11.4801971,46.2147436 L7.528899,47.2916667 C6.33086981,47.6169872 5.49554671,48.7275641 5.49554671,49.9951923 L5.49554671,61 Z"
              id="Fill-10"
              fill="#36506C"
            ></path>
            <path
              d="M18.2310063,21.5 C17.4124346,21.5 16.6707758,21.841 16.1433739,22.468 C15.615972,23.095 15.3962213,23.8815 15.5335655,24.69 L16.451025,30.212 C16.6707758,31.5375 17.807986,32.5 19.1484657,32.5 L20.8515343,32.5 C22.192014,32.5 23.3292242,31.5375 23.548975,30.212 L24.4664345,24.69 C24.6037787,23.8815 24.384028,23.095 23.8566261,22.468 C23.3292242,21.841 22.5875654,21.5 21.7744875,21.5 L18.2310063,21.5 Z M20.8515343,38 L19.1484657,38 C15.1105453,38 11.6989145,35.107 11.0341684,31.114 L10.1112151,25.592 C9.71566375,23.1995 10.3859036,20.763 11.9516279,18.9095 C13.5228459,17.0615 15.808254,16 18.2310063,16 L21.7744875,16 C24.191746,16 26.4826478,17.0615 28.0483721,18.915 C29.6140964,20.7685 30.2843362,23.1995 29.8887849,25.592 L28.9658316,31.114 C28.3010855,35.107 24.8894547,38 20.8515343,38 L20.8515343,38 Z"
              id="Fill-12"
              fill="#36506C"
            ></path>
            <path
              d="M68.5027937,5.58615779 C60.5797933,5.58615779 52.6623804,8.60343709 46.6279372,14.6324081 C34.5702258,26.6903502 34.5702258,46.3138408 46.6279372,58.3717829 C58.6856487,70.4241375 78.3087639,70.429725 90.3720628,58.3717829 C102.424187,46.3138408 102.424187,26.6903502 90.3720628,14.6324081 C84.343207,8.60343709 76.4202067,5.58615779 68.5027937,5.58615779 M68.5027937,73 C59.1494068,73 49.7960199,69.4407279 42.6776119,62.3221838 C28.440796,48.0850955 28.440796,24.913508 42.6776119,10.6820073 C56.903253,-3.55508099 80.0743972,-3.5662561 94.3223881,10.6820073 C108.559204,24.913508 108.559204,48.0850955 94.3223881,62.3221838 C87.2039801,69.4407279 77.8505932,73 68.5027937,73"
              id="Fill-14"
              fill="#36506C"
            ></path>
            <g id="Group-18" transform="translate(83.000000, 54.000000)">
              <mask id="mask-4" fill="white">
                <use xlink:href="#path-3"></use>
              </mask>
              <g id="Clip-17"></g>
              <path
                d="M8.43625714,10.8053714 L25.7188286,31.3750857 L31.2011143,31.2525143 L31.3738286,25.6978 L10.6982571,8.4598 L8.43625714,10.8053714 Z M23.1726857,37.0022286 L0.937114286,10.5435143 L10.2859714,0.865942857 L37.0232571,23.1572286 L36.6109714,36.7125143 L23.1726857,37.0022286 Z"
                id="Fill-16"
                fill="#36506C"
                mask="url(#mask-4)"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
