import dayjs from 'dayjs';

export const getToken = () => {
  return localStorage.getItem('access') || '';
};

export const dateSort = (a: string, b: string) => {
  if (!a) return -1;
  return dayjs(a).isBefore(dayjs(b)) ? -1 : dayjs(a).isSame(dayjs(b)) ? 0 : 1;
};

export const stringSort = (a: string, b: string) => a.toLowerCase().localeCompare(b.toLowerCase());
